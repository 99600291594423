import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"


export const HowWeWork = ({ slice }) => {



  return (

    <section className="c-process o-wrapper o-section--b" aria-label="Our process">
      <div className="c-process__heading c-heading c-heading--center">
        <h2 className="c-heading__kicker">{slice.primary.how_kicker}</h2>
        <div className="c-heading__heading">
          <RichText render={slice.primary.how_title.richText} />
        </div> {/* .c-heading__heading */}
      </div> {/* .c-process__heading */}
      {/* List */}
      {slice.items.length > 0 &&
        <ol className="c-process__list">
          {slice.items.map((item, i) => (
            <li key={i} className="c-process__item">
              <div className="c-process__info">
                <h3 className="c-process__title c-title">
                  <span className="c-process__num">{i + 1}. </span>
                  {item.item_title}
                </h3>
                {item.item_content &&
                  <div className="c-process__text u-text">
                    <RichText render={item.item_content.richText} />
                  </div>}
              </div> {/* .c-process__info */}
              {item.item_image && item.item_image.url &&
                <div className="c-process__pic c-pic c-pic--cover">
                  <GatsbyImage image={item.item_image.gatsbyImageData} />
                </div>
              }
            </li>
          ))}
        </ol>}
    </section>
  )

}

export const query = graphql`
  fragment PostDataBodyHowWeWork on PrismicServicesDataBodyHowWeWork {
    primary {
      how_kicker
      how_title {
        richText
      }
    }
    items {
      item_title
      item_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
      item_content{
        richText
      }
    }
  }
  
`