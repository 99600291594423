
import React, {useEffect} from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/services'

const Services = ({ data }) => {

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);

  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;
  
  let filtersList = data.allPrismicFilter ? data.allPrismicFilter.edges : null;

  const $services = data.prismicServices.data;
  const $servicesTitle = { title: $services.services_title, kicker: $services.services_kicker }

  
  //Service groups slices union
  let serviceGroupsArr = []
  let servicesSlices = []

  $services.body.forEach((element, index) => {
    if (element.slice_type === 'services') {
      serviceGroupsArr.push(element)
    } else {
      servicesSlices[index] = element;
    }
  });

  if (serviceGroupsArr.length > 0) {
    servicesSlices[0] = {};
    servicesSlices[0].items = serviceGroupsArr;
    servicesSlices[0].slice_type = 'services';
  }



  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs}>

      <Seo metadata={$metaData} title={$services.page_title ? $services.page_title : null} description={$services.page_description ? $services.page_description : null} keywords={$services.page_keywords ? $services.page_keywords : null}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/services.services.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/services.process.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/components.filter.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/services.tech.css')} rel="stylesheet" type="text/css" />


      </Helmet>



      <main>

        <SliceZone slices={servicesSlices} components={components} context={{services: $servicesTitle, filters: filtersList}} />

      </main>


    </Layout>


  )
}

export const query = graphql`
  query ServicesQuery {
    prismicServices {
      data {
        body {
          ... on PrismicServicesDataBodyServices {
            slice_type
          }
          ...PostDataBodyServicesGroups
          ... on PrismicServicesDataBodyHowWeWork {
            slice_type
          }
          ...PostDataBodyHowWeWork
          ... on PrismicServicesDataBodyTechStack {
            slice_type
          }
          ...PostDataBodyTechStack
        }
        services_kicker
        services_title {
          richText
        }
        uid
        page_title
        page_description
        page_keywords
      }
    },
    allPrismicFilter {
      edges {
        node {
          data {
            filter_name
          }
          uid
        }
      }
    },
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default Services
