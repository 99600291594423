import * as React from 'react'
import { graphql } from 'gatsby'


export const Technologies = ({ context, slice }) => {

  return (

    slice.items.length > 0 &&
    <section className="c-tech js-filter js-filter__onInit o-section--b" aria-label={slice.primary.tech_title}>
      <h2 className="c-tech__heading c-heading__heading o-wrapper">{slice.primary.tech_title}</h2>

      {/* Filters */}

      {/* FILTER */}
      {context.filters.length > 0 &&
        <nav className="c-tech__filters c-filter" aria-label="Filtered by">
          <h2 className="u-hidden-for-seo">Filtered by</h2>
          <div className="c-filter__wrapper o-wrapper">
            <ul className="c-filter__list">

                <li className="c-filter__item">
                  <button type="button" class="c-filter__btn js-filter__btn is-active" data-filter="mobile" tabindex="-1">Mobile development</button>
                </li>
                <li className="c-filter__item">
                  <button type="button" class="c-filter__btn js-filter__btn" data-filter="web" tabindex="-1">Web development</button>
                </li>
                <li className="c-filter__item">
                  <button type="button" class="c-filter__btn js-filter__btn" data-filter="design" tabindex="-1">UX/UI Design</button>
                </li>

            </ul> {/* .c-filter__list */}
          </div> {/* .c-filter__wrapper */}
        </nav>
      }


      {/* Tech */}
      <ul className="c-tech__list o-wrapper">
        {slice.items.map((item, i) => (
          item.tech_image && item.tech_image.url &&
          <li key={i} className={"c-tech__item js-filter__item is-active"} data-filter={item.tech_groups} style={{ fontSize: `${item.tech_image.dimensions.width}px` }}>
            <img src={item.tech_image.url}
              width={item.tech_image.dimensions.width}
              height={item.tech_image.dimensions.height}
              title={item.tech_name}
              alt={item.tech_name}
              class="c-tech__logo" />
          </li>
        ))}

      </ul> {/* .c-tech__list */}
    </section>
  )

}

export const query = graphql`
  fragment PostDataBodyTechStack on PrismicServicesDataBodyTechStack {

          items {
        tech_groups
      tech_image {
          dimensions{
        width
        height
      }
      url
    }
    tech_name
  }
    primary {
          tech_title
        }
        }
        
`