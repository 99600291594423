import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"
import { Waypoint } from 'react-waypoint';
import { useLayoutEffect } from 'react';


export const ServiceGroups = ({ context, slice }) => {

  const $servicesGroupsList = slice.items;

  //Waypoints
  let $parent = null;
  let imgSelector = null;
  let imgs = null;
  let navLinksSelector = null;
  let navLinks = null;
  let $nextItem = null;
  let subgroupCount = 0;


  //DOM reading
  useLayoutEffect(() => {

    $parent = document.getElementById('js-service');

    imgSelector = '.js-service__pic';
    imgs = document.querySelectorAll(imgSelector);

    navLinksSelector = '.js-service-nav__link';
    navLinks = document.querySelectorAll(navLinksSelector);

  });

  useEffect(() => {
    navLinks.forEach( ($link) => {
      $link.addEventListener('click', ()=>{
        document.querySelector('html').classList.add('smooth');
      })
    })
  }, []);

  //HandleWaypoints

  const handleGroupEnter = (index) => {

    $nextItem = document.querySelector(navLinksSelector + '[data-group="' + index + '"]')

    navLinks.forEach(($item) => {
      $item.classList.remove('is-active');
    });
    $nextItem.classList.add('is-active');

  }


  const handleSubGroupEnter = ($el) => {

    let $img = $el.querySelector(imgSelector);

    imgs.forEach(($img) => {
      $img.classList.remove('is-visible');
    });
    $img.classList.add('is-visible');

  }

  // servicesWaypointFix
  function servicesWaypointFix() {
    
    let $start = document.querySelector('.c-service__start');
    let $end = document.querySelector('.c-service__end');
    let bodyTop = document.body.getBoundingClientRect().top;
    let startTop = $start.getBoundingClientRect().top;
    let endTop = $end.getBoundingClientRect().top;

    let startOffset = bodyTop + startTop;
    
    // console.log('Body', bodyTop)
    // console.log('Start', startTop)
    // console.log('End', endTop)
    // console.log('startOffset', startOffset)
    // console.log('WindowHeight', window.innerHeight)

    if( startOffset < 0 ){
      $parent.classList.add('is-fixed','fixed-hardcoded');

      // if( endOffset < 0 ){
      if( endTop < window.innerHeight ){
        $parent.classList.add('is-fixed--bottom','fixed-bottom-hardcoded');
      }

    }

  };

  useEffect(() => {
    servicesWaypointFix()
  }, []);



  return (

    <section className="c-services" aria-label="Services">

      {context.services.kicker &&
        <div className="c-services__hero c-hero c-services-hero o-wrapper">
          <div className="c-services-hero__heading c-heading c-heading--center">
            <h2 className="c-heading__kicker">{context.services.kicker}</h2>
            <div className="c-heading__heading c-title">
              <RichText render={context.services.title.richText} />
            </div>
          </div>
        </div>
      }

      {/* SERVICES */}
      {$servicesGroupsList.length > 0 &&
        <div className="c-services__main o-wrapper o-wrapper--xl o-section">
          <div id="js-service" className="c-services__service c-service">
            {/* Limits */}

            {/* Start: Mientras baja */}
            <div className="c-service__start">
              <Waypoint
                topOffset={'0%'}
                onLeave={({ previousPosition, currentPosition, event }) => {
                  
                  if (currentPosition === 'above' && currentPosition !== previousPosition && previousPosition !== undefined) {

                    $parent.classList.add('is-fixed')

                  }
                  
                }}
                onEnter={({ previousPosition, currentPosition, event }) => {

                  if (currentPosition === 'inside' && currentPosition !== previousPosition && previousPosition !== undefined) {

                    $parent.classList.remove('is-fixed')

                  }
                  
                }}
                // debug='true'
              >
              </Waypoint>
            </div> {/* c-service__start */}

            <div className="c-service__end">
              <Waypoint
                onEnter={({ previousPosition, currentPosition, event }) => {
                  if (currentPosition === 'inside' && currentPosition !== previousPosition && previousPosition !== undefined) {

                    $parent.classList.add('is-fixed--bottom')

                  }
                }}
                onLeave={({ previousPosition, currentPosition, event }) => {
                  if (currentPosition === 'below' && currentPosition !== previousPosition && previousPosition !== undefined) {

                    $parent.classList.remove('is-fixed--bottom')

                  }
                }}
                bottomOffset="0%">
              </Waypoint>
            </div>

            {/* Nav */}
            <nav className="c-service__nav c-service-nav">
              <h2 className="u-hidden-for-seo">Services' navigation</h2>
              <ul className="c-service-nav__list">
                {$servicesGroupsList.map((item, i) => (
                  <li className="c-service-nav__item">
                    <a href={`#dy-service__group-${i + 1}`} class={`c-service-nav__link js-service-nav__link ${i === 0 ? 'is-active' : ''}`} data-group={i + 1} title={item.primary.service_title} tabindex="-1">
                      <span class="c-service-nav__tap u-tap-size"></span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav> {/* .c-service-nav */}

            {$servicesGroupsList.map((item, i) => (

              <>
                <div key={i} id={`dy-service__group-${i + 1}`} className="c-service__group js-service__group o-section" data-group={i + 1}>
                  
                  <Waypoint
                    onEnter={() => {
                      handleGroupEnter(i + 1)
                    }}
                    topOffset="50%">

                    <div>
                      {item.items.length > 0 &&

                        item.items.map((subitem, j) => {
                          subgroupCount++
                          return (

                            <>
                              {/* <Waypoint
                              onEnter={() => {
                                handleSubGroupEnter(j)
                              }}
                              onLeave={() => {
                                // handleSubGroupLeave(j)
                              }}
                              topOffset="33%"> */}


                              <div
                                id={`dy-subgroup-${i}-${j}`}
                                className={`c-service__subgroup ${j === 0 ? 'c-service__subgroup--highlight' : ''} js-service__subgroup o-section--t`}>

                                <Waypoint
                                  onEnter={() => {
                                    handleSubGroupEnter(document.getElementById(`dy-subgroup-${i}-${j}`))
                                  }}
                                  topOffset="33%">

                                  <div>
                                    {subitem.service_group_image && subitem.service_group_image.url &&
                                      <GatsbyImage className={`c-service__pic js-service__pic c-pic c-pic--cover ${subgroupCount === 1 ? 'is-visible' : ''}`} image={subitem.service_group_image.gatsbyImageData} />
                                    }

                                    {j === 0 && item.primary.service_title && <h3 className="c-service__heading c-heading__heading">{item.primary.service_title}</h3>}

                                    {subitem.service_group_heading &&
                                      <div className="c-service__title c-title">
                                        <RichText render={subitem.service_group_heading.richText} />
                                      </div>
                                    }
                                    {subitem.service_group_content &&
                                      <div className="c-service__text u-text">
                                        <RichText render={subitem.service_group_content.richText} />
                                      </div>
                                    }
                                  </div>

                                </Waypoint>

                              </div> {/* .c-service__subgroup */}

                            </>

                          )
                        }
                        )}
                    </div>

                  </Waypoint>
                  
                </div> {/* .c-service__group */}

              </>

            ))}

          </div> {/* .c-service */}
        </div>
      }
    </section>
  )

}

export const query = graphql`
  fragment PostDataBodyServicesGroups on PrismicServicesDataBodyServices {
    primary {
      service_title
    }
    items {
      service_group_content {
        richText
      }
      service_group_heading {
        richText
      }
      service_group_image {
        url
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
      }
    } 
  }
  
`